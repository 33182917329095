import PProviderPartnersView from '@/views/Provider/PProvider/partners'
import PProviderService from '@/services/api/Provider/PProvider'
// import RulesService from '@/services/rules'

export default {
  name: 'v-view-account',
  components: {
    PProviderPartnersView,
  },
  data() {
    return {
      busy: {
        representative: false,
      },
      pProvider: {
        legalRepresentative: {},
      },
    }
  },
  methods: {

    /**
     * 
     */
    async getProvider() {
      this.busy.representative = true
      this.pProvider = await PProviderService.getById(this.$provider.id)
      this.busy.representative = false
    },

    /**
     * 
     */
    handleFormError(payload) {
      PProviderService.translate(payload).then(this.$toast.error)
    },

    /**
     * 
     */
    handleFormReset() {
      this.getProvider()
    },

    /**
     * 
     */
    async handleFormSubmit() {
      this.busy.representative = true
      await PProviderService.legalRepresentative(this.pProvider).then(this.handleFormSuccess).catch(this.handleFormError)
      this.busy.representative = false
    },

    /**
     * 
     */
    handleFormSuccess(response) {
      PProviderService.translate({ response }).then(this.$toast.success)
    }
  },
  mounted() {
    this.handleFormReset()
  },
}